import React from "react";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faArrowUpRightFromSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";

export default function FormLinks() {
  const theme = useTheme();
  const { activeStudent } = useActiveStudent();

  // Styles
  const moreForms = {
    marginTop: theme.spacing(4),
  };

  return (
    <Grid container sx={moreForms}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          Forms and info from the TPS public website
        </Typography>
        <Typography variant="body2">
          Jump straight to the forms (&#8239;
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" />
          &#8239;) or learn more (&#8239;
          <FontAwesomeIcon icon={faInfoCircle} size="sm" />
          &#8239;)
        </Typography>
      </Grid>
      <Grid item lg={8}>
        <List>
          {activeStudent?.isSecondary && (
            <ListItem
              button
              component={Link}
              target="_blank"
              rel="noopener noreferrer"
              href="https://tacoma-wa.finalforms.com"
            >
              <ListItemText
                primary={
                  <Typography color="primary">
                    Athletics Registration Forms{" "}
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </Typography>
                }
                secondary={`Login to FinalForms.com for middle and high school sports.`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  component={Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="secondary"
                  title="Athletics and Activities"
                  href="https://www.tacomaschools.org/departments/athletics-and-activities"
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
          <ListItem
            button
            component={Link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa-tacoma-psv.edupoint.com/PXP2_Login.aspx"
          >
            <ListItemText
              primary={
                <Typography color="primary">
                  Choice Enrollment Forms{" "}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Typography>
              }
              secondary={
                <span>
                  Apply to choose a non-neighborhood school. Login to ParentVue
                  and select <em>Online Registration</em> to get started.
                </span>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                component={Link}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
                title="Enrollment Services"
                href="https://www.tacomaschools.org/departments/enrollment/choice-enrollment"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            button
            component={Link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.tacomaschools.org/departments/athletics-and-activities/hib"
          >
            <ListItemText
              primary={
                <Typography color="primary">
                  HIB Incident{" "}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Typography>
              }
              secondary="Report harassment, intimidation or bullying."
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                component={Link}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
                title="HIB Incident"
                href="https://www.tacomaschools.org/departments/athletics-and-activities/hib"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            button
            component={Link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://resources.finalsite.net/images/v1594152999/tacomak12waus/bstc9gflrjdwepqoj2pq/housingquestionnaire.pdf"
          >
            <ListItemText
              primary={
                <Typography color="primary">
                  Student Housing Questionnaire{" "}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Typography>
              }
              secondary="In transition? Unstable housing situation? Let us connect you with the support to keep your education on track."
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                component={Link}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
                title="McKinney-Vento Homeless Assistance"
                href="https://www.tacomaschools.org/departments/mva"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            button
            component={Link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.parchment.com"
          >
            <ListItemText
              primary={
                <Typography color="primary">
                  Transcript Request{" "}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Typography>
              }
              secondary="Get official student transcripts through Parchment.com."
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                component={Link}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
                title="Records requests"
                href="https://www.tacomaschools.org/legal-services/records-requests"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}
