import dayjs from "dayjs";
import { useEffect } from "react";

const relativeTime = require("dayjs/plugin/relativeTime");
const isToday = require("dayjs/plugin/isToday");
const isTomorrow = require("dayjs/plugin/isTomorrow");
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export const formatDate = (date) => {
  return dayjs(date).format("M/D/YY");
};

export const formatTime = (time) => {
  let today = dayjs().format("M/D/YY");
  return dayjs(today + " " + time).format("h:mma");
};

export const formatDateTime = (date) => {
  return dayjs(date).format("M/D/YY h:mma");
};

export const isPm = (time) => {
  let today = dayjs().format("M/DD/YY");
  if (dayjs(today + " " + time).format("a") === "pm") {
    return true;
  } else return false;
};

export const relativeDay = (date) => {
  date = dayjs(date);
  const diff = date.diff(dayjs(), "day");
  if (!!date) {
    if (date.isToday()) {
      return <strong>TODAY</strong>;
    } else if (date.isTomorrow()) {
      return "Tomorrow";
    } else if (diff === 1) {
      return "Day after tomorrow";
    } else return date.fromNow();
  } else return "";
};

export const decodeHtml = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

export const getCurrentSchoolYear = () =>
  new Date().getMonth() >= 8
    ? new Date().getFullYear() + 1
    : new Date().getFullYear();

export const getGradeName = (grade) => {
  //"09" becomes "9th Grade"
  switch (grade) {
    case "PS":
      return "Preschool";
    case "PP":
      return "Preschool (pre-registered)";
    case "K":
    case "K2":
    case "K3":
      return "Kindergarten";
    case "KP":
      return "Kindergarten (pre-registered)";
    default:
      //https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
      let i = grade?.replace(/^0+/, "");
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st Grade";
      }
      if (j === 2 && k !== 12) {
        return i + "nd Grade";
      }
      if (j === 3 && k !== 13) {
        return i + "rd Grade";
      }
      return i + "th Grade";
  }
};

export const getFirstName = (fullName) => {
  return !!fullName ? fullName?.trim()?.split(" ")[0] : "";
};

export const isFullUrl = (url) => {
  if (url?.trim().toLowerCase().indexOf("http") === 0) {
    return true;
  } else return false;
};

export const widowUnmaker = (text) => {
  text = text?.trim();
  const lastSpace = text?.lastIndexOf(" ");
  return (
    <span>
      {text?.substring(0, lastSpace)}&nbsp;{text?.substring(lastSpace + 1)}
    </span>
  );
};

export const truncateText = (text, length) => {
  text = text?.trim();
  const max = length ?? 100; //default to 100 characters
  if (text?.length > max + 3) {
    const shorter = text.substring(0, max);
    const lastSpace = shorter.lastIndexOf(" ");
    return (
      <span>
        {shorter.substring(0, lastSpace)}&nbsp;
        {shorter.substring(lastSpace + 1)}...
      </span>
    );
  } else {
    return widowUnmaker(text);
  }
};

export const blob =
  "https://dnastash.blob.core.windows.net/apps-images/Compass/";

export const showFeatures = {
  survey: parseInt(process.env.REACT_APP_SHOW_SURVEYS) === 1 ? true : false,
  attendance:
    parseInt(process.env.REACT_APP_SHOW_ATTENDANCE) === 1 ? true : false,
  grades: parseInt(process.env.REACT_APP_SHOW_GRADES) === 1 ? true : false,
  covid: parseInt(process.env.REACT_APP_SHOW_COVID) === 1 ? true : false,
  buses: parseInt(process.env.REACT_APP_SHOW_BUSES) === 1 ? true : false,
  // add other environment variables as needed
};

export function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
