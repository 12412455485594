//#region Imports
import {
  Box,
  Grid,
  Typography,
  Tab,
  Tabs,
  Divider,
  Badge,
  useMediaQuery,
} from "@mui/material";
import { useUser } from "../../services/contexts/userContext";
import { ScrollToTopOnMount } from "../../services/utilities";
import {
  StudentPicker,
  useActiveStudent,
} from "../../services/contexts/activeStudentContext";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useCompassEligiblePrograms } from "../../services/hooks/programsHook";
import { useGetEligibleDsaPartners } from "../../services/hooks/dsaHook";
import { useGetWaitlistByStudentId } from "../../services/hooks/waitlistHook";
import ActivitiesAbout from "../common/ActivitiesAbout";
import ActivitiesTitle from "../common/ActivitiesTitle";
import { useTheme } from "@mui/material/styles";
import { blob } from "../../services/utilities";
//#endregion

export default function Activities() {
  const theme = useTheme();
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { activeStudent } = useActiveStudent();

  const waitlistQuery = useGetWaitlistByStudentId(activeStudent?.studentId);
  const waitlist = waitlistQuery?.data?.data;
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const eligibleActivitesQuery = useCompassEligiblePrograms(
    activeStudent?.studentId
  );
  const eligible = eligibleActivitesQuery?.data?.data;

  const eligibleDsaPartnerQuery = useGetEligibleDsaPartners(
    activeStudent?.studentId
  );
  const eligibleDsaPartners = eligibleDsaPartnerQuery?.data?.data;

  const ChangeTab = (event, newValue) => {
    navigate(`/activities/${newValue}`);
  };
  const GetSelectedTab = () => {
    return location.pathname
      .replace("/activities/", "")
      .replace("/activities", "");
  };

  //#region Styles
  const aboutWrapStyles = {
    textAlign: "right",
  };
  const labelStyles = { mx: 1, my: "0.25rem" };
  //#endregion

  //#region Render
  return (
    <>
      <ScrollToTopOnMount />
      <ActivitiesTitle />
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="lede" component="p">
            Discover {user.role === "guardian" && "and register for "}before and
            after school activities, clubs,{" "}
            {!activeStudent?.isSecondary && "sports, "}field trips
            and&nbsp;more.{" "}
            {user.role === "student" && (
              <Typography variant="body2" component="span">
                When you find an activity you're interested in, you'll need a
                parent/guardian to log into Compass and register you. (It's like
                a better version of a permision slip.)
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          style={largeScreen ? { textAlign: "right" } : {}}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 150,
              height: 150,
              borderRadius: "50%",
              background: "#fff",
            }}
          >
            <img
              src={`${blob}${
                activeStudent?.isSecondary
                  ? "club-b-logo"
                  : "beyond-the-bell-logo"
              }.svg?v=3`}
              alt={
                activeStudent?.isSecondary
                  ? "Club B logo"
                  : "Beyond the Bell logo"
              }
              style={{ width: "100%", maxWidth: 150, margin: "0.5rem" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} md={2} sx={aboutWrapStyles}>
          <ActivitiesAbout />
        </Grid>
      </Grid>

      <StudentPicker />

      <Tabs
        value={GetSelectedTab()}
        aria-label="Activities"
        onChange={ChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          value={""}
          label={
            <Box sx={labelStyles}>
              {user.role === "guardian"
                ? `${activeStudent?.firstName}'s Current
                Activities`
                : "My Current Activities"}
            </Box>
          }
          aria-label="Current activities"
        />

        {waitlist?.length > 0 &&
          waitlist?.some((wl) => wl?.status?.toLowerCase() === "active") && (
            <Tab
              value={"waitlist"}
              label={
                <Badge badgeContent={waitlist?.length} color="secondary">
                  <Box sx={labelStyles}>Waitlists</Box>
                </Badge>
              }
              aria-label="Waitlisted activites"
            />
          )}

        {/* Badge needs to be in label or tab breaks */}
        {/* https://stackoverflow.com/questions/38697630/material-ui-adding-badge-to-a-tab-in-material-ui-tabbar-tabs/46491649#46491649?newreg=1078792fbf0c4e5eb2c14c8f0b097b28 */}
        <Tab
          value={"eligible"}
          label={
            <Badge
              badgeContent={eligible?.length + eligibleDsaPartners?.length}
              color="secondary"
            >
              <Box sx={labelStyles}>Eligible</Box>
            </Badge>
          }
          aria-label="eligible"
        />
        <Tab
          value={"history"}
          aria-label="history"
          label={<Box sx={labelStyles}>History</Box>}
        />
      </Tabs>
      <Divider />

      <Outlet />
    </>
  );
  //#endregion
}
