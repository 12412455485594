//#region Imports
import { useMemo, useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router";
import { alpha } from "@mui/material/styles";
import { useCompassProgramDetails } from "../../services/hooks/waitlistHook";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import Error from "../common/Error";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarRange,
  faCalendarDays,
  faLocationDot,
  faHandshakeSimple,
  faChalkboardUser,
  faSchool,
  faClock,
  faTag,
  faShirt,
} from "@fortawesome/pro-solid-svg-icons";
import {
  formatDate,
  formatTime,
  formatDateTime,
} from "../../services/utilities";
import dayjs from "dayjs";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ActivityRegistration from "./ActivityRegistration";
import ActivityWithdraw from "./ActivityWithdraw";
import ActivitiesTitle from "./ActivitiesTitle";
import ActivityWaitlistDialog from "./ActivityWaitlistDialog";
import WaitlistRemoveDialog from "./WaitlistRemoveDialog";
import {
  useGetWaitlistByStudentId,
  useGetGetWaitlistStatuses,
} from "../../services/hooks/waitlistHook";
import { useUser } from "../../services/contexts/userContext";
import { widowUnmaker } from "../../services/utilities";
//#endregion

export default function ActivityDetails() {
  const { programId } = useParams();
  const { activeStudent } = useActiveStudent();
  const { user } = useUser();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const commonStyles = useCommonStyles();
  const waitlistStatusQuery = useGetGetWaitlistStatuses();
  const waitlistStatuses = waitlistStatusQuery?.data?.data;
  const waitlistQuery = useGetWaitlistByStudentId(activeStudent?.studentId);

  const [activeStatusId, setActiveStatusId] = useState();
  const [inactiveStatusId, setInactiveStatusId] = useState();

  const [regDialogOpen, setRegDialogOpen] = useState(false);
  const [withdrawalDialogOpen, setWithdrawalDialogOpen] = useState(false);
  const [waitlistDialogOpen, setWaitlistDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  useEffect(() => {
    waitlistStatuses?.forEach((status) => {
      if (status.status === "Active") {
        setActiveStatusId(status?.waitlistStatusId);
      } else if (status?.status === "Inactive – removed from waitlist") {
        setInactiveStatusId(status?.waitlistStatusId);
      }
    });
  }, [waitlistStatuses]);

  const onCloseRegDialog = () => {
    setRegDialogOpen(false);
  };
  const onCloseWithdrawalDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setWithdrawalDialogOpen(false);
  };
  const onCloseWaitlistDialog = () => {
    setWaitlistDialogOpen(false);
  };

  const onCloseRemoveDialog = () => {
    setRemoveDialogOpen(false);
  };

  const query = useCompassProgramDetails(activeStudent?.studentId, programId);
  const details = query?.data?.data;

  const capacity = details?.maxEnrollment - details?.participantCount;

  const hasActiveWaitlist = details?.hasActiveWaitlist;

  function isRegistrationOpen(details) {
    if (!details?.registrationEndDate) {
      return false;
    }
    const currentDate = dayjs().format("YYYY-MM-DD");
    const registrationEndDate = dayjs(details.registrationEndDate).format(
      "YYYY-MM-DD"
    );
    return currentDate <= registrationEndDate;
  }

  const regButtonProps = useMemo(() => {
    if (details == null)
      return {
        text: "Loading",
        disabled: true,
        onClick: () => {},
      };
    else if (dayjs() > dayjs(details.endDate)) {
      return {
        text: "Program has ended",
        disabled: true,
        onClick: () => {
          setWaitlistDialogOpen(true);
        },
      };
    } else if (details.isEnrolled && dayjs(details.endDate) > dayjs()) {
      return {
        text: "Withdraw",
        disabled: false,
        onClick: () => {
          setWithdrawalDialogOpen(true);
        },
      };
    } else if (
      (hasActiveWaitlist &&
        !details?.isWaitlisted &&
        !details?.hasEverBeenWaitlisted &&
        isRegistrationOpen(details)) ||
      (capacity <= 0 &&
        !details?.isWaitlisted &&
        !details?.hasEverBeenWaitlisted &&
        isRegistrationOpen(details))
    ) {
      return {
        text: "Add to waitlist",
        disabled: false,
        onClick: () => {
          setWaitlistDialogOpen(true);
        },
      };
    } else if (details?.isWaitlisted || details?.hasEverBeenWaitlisted) {
      return {
        text: "On the waitlist",
        disabled: true,
        // onClick: () => {
        //   setRemoveDialogOpen(true);
        // },
      };
    } else if (details.overlapsWithEnrolledProgram) {
      return {
        text: "Cannot Register - Schedule conflicts with a currently enrolled activity",
        disabled: true,
        onClick: () => {},
      };
    } else if (!details.hasActiveWaitlist && details.isEligible) {
      return {
        text: "Register",
        disabled: false,
        onClick: () => {
          setRegDialogOpen(true);
        },
      };
    } else {
      return {
        text: "Not open for registration",
        disabled: true,
        onClick: () => {},
      };
    }
  }, [waitlistQuery, details]);

  const renderGrades = (grades) => {
    // list returned from API is in alpha order and numbers have leading zeros
    let list = [];
    grades?.forEach((grade) => {
      if (grade[0] === "K") {
        // getting the first character only should handle occassional odd data like "K3"
        list.unshift("K");
      } else if (grade[0] === "P") {
        list.unshift("P");
      } else if (!parseInt(grade)) {
        list.unshift(grade); // handles any alpha grade that doesn't start with K or P, and puts all its characters at the front (TK, for example)
      } else list.push(parseInt(grade).toString());
    });
    return widowUnmaker(list?.join(", "));
  };

  //#region Styles
  const registrationWrapperStyles = {
    ...commonStyles.paperPadding,
    maxWidth: 375,
    minWidth: 300,
    mb: largeScreen ? 2 : 3,
    mt: 2,
    ml: largeScreen ? 3 : "auto",
    mr: largeScreen ? 0 : "auto",
    float: largeScreen ? "right" : "none",
    background: theme?.palette?.background?.neutral,
  };
  const detailsWrapperStyles = {
    mt: 3,
    "& p": {
      mb: 2,
      mx: 1,
    },
  };
  const detailIconStyles = {
    width: "1.5rem",
  };
  const fixedCost = {
    backgroundColor: alpha(theme?.palette?.neutral?.light, 0.1),
    py: "0.8rem",
    px: "1rem",
    width: "fit-content",
    borderRadius: "6px",
  };
  //#endregion

  //#region Render
  return (
    <>
      <ActivitiesTitle />

      {query.isLoading && <Skeleton />}
      {query.isError && (
        <Error message="There was an issue loading details for this program." />
      )}

      {/* user not allowed to see details */}
      {query.isSuccess && details === null && (
        <Typography>This program's details are unavailable.</Typography>
      )}

      {query.isSuccess && details && (
        <>
          <Paper sx={registrationWrapperStyles}>
            {dayjs(dayjs().format("YYYY-MM-DD")) <
              dayjs(details.registrationEndDate).add(1, "day") && (
              <Typography
                color={capacity <= 0 ? "error" : "secondary"}
                sx={{ textAlign: "center" }}
              >
                {!hasActiveWaitlist && capacity > 0 ? (
                  <strong>
                    {capacity} spot{capacity !== 1 && "s"} available
                  </strong>
                ) : (
                  <strong>Waitlist available</strong>
                )}
              </Typography>
            )}
            {user?.role === "guardian" ? (
              <Button
                variant="contained"
                fullWidth
                size="large"
                disabled={regButtonProps?.disabled}
                onClick={regButtonProps?.onClick}
              >
                {regButtonProps?.text}
              </Button>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <Typography>
                  <strong>Interested?</strong>
                </Typography>
                <Typography variant="body2">
                  Ask your parent/guardian to login to Compass and
                  register&nbsp;you.
                </Typography>
              </Box>
            )}
            {!!details.registrationStartDate &&
              !!details.registrationEndDate && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ textAlign: "center" }}
                >
                  Registration open:{" "}
                  {formatDateTime(details.registrationStartDate)} -{" "}
                  {formatDate(details.registrationEndDate)}&nbsp;11:59pm
                </Typography>
              )}
          </Paper>
          <Typography
            variant="h2"
            // sx={{ maxWidth: "calc(100% - 300px) !important" }}
            // sx={{ maxWidth: "100% !important" }}
          >
            {details.title ?? details.activityName}
          </Typography>
          {details?.isFixedCost && details?.cost > 0 && (
            <Typography variant="h5" sx={fixedCost}>
              ${details.cost} <em>required</em> cost
            </Typography>
          )}
          <Typography variant="caption" component="div">
            <FontAwesomeIcon icon={faTag} /> {details.activityTypeName}
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            className={`markdown theme-${theme?.name?.toLowerCase()}`}
            sx={{ maxWidth: "100% !important" }}
          >
            <Markdown remarkPlugins={[remarkGfm]}>
              {details.description}
            </Markdown>
          </Typography>

          <Grid
            container
            justifyContent="space-between"
            sx={detailsWrapperStyles}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="body2">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  style={detailIconStyles}
                />{" "}
                <strong>Location</strong>: {widowUnmaker(details.locationName)}
              </Typography>
              <Typography variant="body2">
                <FontAwesomeIcon icon={faSchool} style={detailIconStyles} />{" "}
                <strong>Grade&nbsp;Levels</strong>:{" "}
                {renderGrades(details.grades)}
              </Typography>
              {details?.isTeeShirtRequired && (
                <Typography variant="body2">
                  <FontAwesomeIcon icon={faShirt} style={detailIconStyles} />{" "}
                  <strong>T-Shirt Required</strong>: t-shirt will
                  be&nbsp;provided
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2">
                <FontAwesomeIcon
                  icon={faCalendarRange}
                  style={detailIconStyles}
                />{" "}
                <strong>Start &amp; End Dates</strong>:{" "}
                {formatDate(details.startDate)}&nbsp;-&nbsp;
                {formatDate(details.endDate)}
              </Typography>
              <Typography variant="body2">
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  style={detailIconStyles}
                />{" "}
                <strong>Days</strong>: {widowUnmaker(details.cycleName)}
              </Typography>
              <Typography variant="body2">
                <FontAwesomeIcon icon={faClock} style={detailIconStyles} />{" "}
                <strong>Time</strong>: {formatTime(details.defaultStartTime)}
                &nbsp;-&nbsp;
                {formatTime(details.defaultEndTime)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2">
                <FontAwesomeIcon
                  icon={faHandshakeSimple}
                  style={detailIconStyles}
                />{" "}
                <strong>Provider</strong>: {widowUnmaker(details.provider)}
              </Typography>
              <Typography variant="body2">
                <FontAwesomeIcon
                  icon={faChalkboardUser}
                  style={detailIconStyles}
                />{" "}
                <strong>Instructor(s)</strong>:{" "}
                {widowUnmaker(details.instructors?.join(", "))}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      <ActivityRegistration
        open={regDialogOpen}
        onClose={onCloseRegDialog}
        details={details}
      />
      <ActivityWithdraw
        open={withdrawalDialogOpen}
        onClose={onCloseWithdrawalDialog}
        details={details}
      />
      <ActivityWaitlistDialog
        open={waitlistDialogOpen}
        activeStatusId={activeStatusId}
        onClose={onCloseWaitlistDialog}
        details={details}
      />
      <WaitlistRemoveDialog
        open={removeDialogOpen}
        inactiveStatusId={inactiveStatusId}
        onClose={onCloseRemoveDialog}
        details={details}
      />
    </>
  );
  //#endregion
}
